import { useApp } from 'hooks';

export default function useCurrency() {
  const { country } = useApp();

  const getCurrency = (isSymbol = true) => {
    if (isSymbol) {
      switch (country) {
        case 'US': return '$';
        case 'CA': return '$';
        default: return '$';
      }
    } else {
      switch (country) {
        case 'US': return 'USD';
        case 'CA': return 'CAD';
        default: return 'USD';
      }
    }
  };

  const formatCurrency = (amount, options = {}, isSymbol = true) => {
    let number;

    if (typeof amount === 'string') {
      number = parseFloat(amount);
    } else if (typeof amount === 'number') {
      number = amount;
    } else {
      throw new TypeError('Amount must be a number or a string representing a number');
    }

    if (Number.isNaN(number)) {
      throw new TypeError('Amount must be a valid number');
    }

    const value = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: options.minimumFractionDigits || 2,
      maximumFractionDigits: options.maximumFractionDigits || 2,
    }).format(number);

    return `${value} ${getCurrency(isSymbol)}`;
  };

  return { getCurrency, formatCurrency };
}
